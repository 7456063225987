/* temp_stylePlugin:src/views/admin/views/publication-details/views/cv/css/loginContainer.css */
.LogForm {
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
.line {
  border-left: 1px solid #FFFFFF;
  width: 0;
  height: 50%;
  position: fixed;
  bottom: 200px;
}
.button {
  background-color: #0007BB !important;
  padding: 3px 2px;
  border-radius: 10px;
  height: 30px;
  width: 120px;
  color: #FFFFFF;
}
.add-list {
  background-color: #a1a1a29f !important;
  padding: 2px 2px;
  border-radius: 100%;
  height: 25px;
  width: 70px;
  color: #FFFFFF;
}
@media screen and (min-width: 640px) {
  .button {
    height: 40px;
    width: 150px;
  }
}
@media screen and (min-width: 1024px) {
  .button {
    height: 40px;
    width: 150px;
  }
}
.button2 {
  background-color: #ABABAC !important;
  padding: 3px 2px;
  border-radius: 10px;
  height: 40px;
  width: 150px;
  color: #FFFFFF;
}
.buttonSignIn {
  background-color: #0007BB !important;
  height: 50px;
  width: 316px;
  color: #FFFFFF;
  border-radius: 25px;
}

/* temp_stylePlugin:src/views/admin/views/publication/css/publicationList.css */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.button-rounded-full.btn {
  border-radius: 9999px;
}

/* temp_stylePlugin:src/views/admin/views/CV/css/filterCvList.css */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.scroll-selected .control-selected {
  overflow-x: auto;
}

/* temp_stylePlugin:src/views/admin/views/CV-details/css/publicationList.css */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

/* temp_stylePlugin:src/views/admin/views/CV-details/views/details/css/loginContainer.css */
.LogForm {
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
.line {
  border-left: 1px solid #FFFFFF;
  width: 0;
  height: 50%;
  position: fixed;
  bottom: 200px;
}
.button {
  background-color: #0007BB !important;
  padding: 2px 2px;
  border-radius: 10px;
  height: 30px;
  width: 100px;
  color: #FFFFFF;
}
.add-list {
  background-color: #a1a1a29f !important;
  padding: 2px 2px;
  border-radius: 100%;
  height: 20px;
  width: 70px;
  color: #FFFFFF;
}
@media screen and (min-width: 640px) {
  .button {
    height: 40px;
    width: 100px;
  }
}
@media screen and (min-width: 1024px) {
  .button {
    height: 40px;
    width: 100px;
  }
}
.button2 {
  background-color: #ABABAC !important;
  padding: 3px 2px;
  border-radius: 10px;
  height: 40px;
  width: 100px;
  color: #FFFFFF;
}
.buttonSignIn {
  background-color: #0007BB !important;
  height: 50px;
  width: 316px;
  color: #FFFFFF;
  border-radius: 25px;
}

/* temp_stylePlugin:src/views/admin/views/CV-details/views/evaluations/css/testResult.css */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

/* temp_stylePlugin:src/views/admin/views/publication-details/css/MenuPublication.css */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.menu-tabs {
  position: relative;
  padding: 0px;
  --tw-text-opacity: 1;
  color: rgb(225 251 50 / var(--tw-text-opacity, 1));
}
.menu-tabs::after {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity, 1));
  content: "";
  height: 3px;
}
.menu-tabs .menu-item {
  z-index: 1;
  border-bottom-width: 3px;
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity, 1));
}
.menu-tabs .menu-item.active {
  --tw-border-opacity: 1;
  border-color: rgb(119 128 130 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(225 251 50 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.menu-tabs .menu-item a:hover,
.menu-tabs .menu-item a:active,
.menu-tabs .menu-item.active a {
  border-radius: 0px;
  --tw-bg-opacity: 1;
  background-color: rgb(225 251 50 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.menu-tabs .menu-item .item-badge {
  background-color: rgb(18 14 228 / .12);
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}
.menu-tabs .menu-item a {
  gap: 0.5rem;
}

/* temp_stylePlugin:src/views/client/views/advert-search/css/menuItemClient.css */
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.menu-item-client > a {
  margin-top: 1rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(167 88 54 / var(--tw-text-opacity, 1));
}
.menu-item-child-client > a > div > i {
  color: #6C6868;
}

/* temp_stylePlugin:src/views/client/views/advert/css/carousel.css */
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  bottom: 16rem;
  --bg-opacity: 0.5;
  border-radius: 9999px;
  padding: 2.5rem 2.5rem;
  box-shadow: 0px 3px 12px 0px #0000004D;
}
.swiper-button-prev:hover,
.swiper-button-next:hover {
  --bg-opacity: 0.9;
  box-shadow: 0px 3px 12px 0px #0000005d;
}
.swiper-wrapper {
  padding-top: 50px;
  padding-bottom: 50px;
}
.swiper {
  --swiper-navigation-size:3rem;
  --swiper-navigation-color:#2E2A2A;
  --swiper-navigation-sides-offset:40px ;
}

/*# sourceMappingURL=../../modules/recruiting/dist/index.css.map */