
    .connect-with{
        border-top: 1px solid #7A7373;
        text-align: center;
        line-height: 0;
        color: #837E7E;
    }
    .connect-with span{
        font-size: 14px;
    }
    .check-box > div >div{
        align-items: center;
    }
    .plo #userName, .plo .relative #password, .plo .relative #new-password, .plo .relative #confirm-password, .plo #email{
        border-radius: 9999px;
        border-width: 2px;
        --tw-border-opacity: 1;
        border-color: rgb(225 251 50 / var(--tw-border-opacity, 1));
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
}
    .plo .relative .absolute .control-btn i{
        font-size: 20px;
        padding-right: 15px;
        --tw-text-opacity: 1;
        color: rgb(225 251 50 / var(--tw-text-opacity, 1))}
    .left{
        background:linear-gradient(180deg, rgba(4, 1, 32, 0.736) 0%, rgba(4, 1, 37, 0.704) 100%),url('./../assets/img/login.jpg');
        background-repeat: no-repeat;
        background-position-y: center;
        background-size: cover;
    }
    .condition-general,.politique-confidentialite{
        background: linear-gradient(90.82deg, rgba(217, 217, 217, 0.2343) 0.74%, rgba(173, 173, 173, 0.217731) 50.23%, rgba(242, 240, 240, 0.2343) 99.72%);
    }