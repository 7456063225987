/* temp_stylePlugin:src/css/thread-comment-item.css */
.thread-comment-item {
}
.thread-comment-item .tiptap {
  padding: 0px;
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.thread-comment-item .tiptap p {
  padding: 0px;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

/* temp_stylePlugin:src/css/thread-comment-form.css */
.thread-comment-form {
  overflow: hidden;
  border-radius: 9999px;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity, 1));
}
.thread-comment-form textarea {
  border-style: none;
}
.thread-comment-form textarea:focus {
  border-style: none;
}

/*# sourceMappingURL=../../packages/thread/dist/index.css.map */