/* temp_stylePlugin:src/main.css */
*,
::before,
::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}
::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.sticky {
  position: sticky;
}
.bottom-0 {
  bottom: 0px;
}
.left-0 {
  left: 0px;
}
.right-0 {
  right: 0px;
}
.top-0 {
  top: 0px;
}
.top-8 {
  top: 2rem;
}
.top-\[-10px\] {
  top: -10px;
}
.top-\[60px\] {
  top: 60px;
}
.\!z-50 {
  z-index: 50 !important;
}
.\!z-\[60\] {
  z-index: 60 !important;
}
.z-10 {
  z-index: 10;
}
.z-40 {
  z-index: 40;
}
.col-span-12 {
  grid-column: span 12 / span 12;
}
.m-1 {
  margin: 0.25rem;
}
.m-\[-1px\] {
  margin: -1px;
}
.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.mb-0 {
  margin-bottom: 0px;
}
.ml-6 {
  margin-left: 1.5rem;
}
.ml-\[-10px\] {
  margin-left: -10px;
}
.ml-auto {
  margin-left: auto;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mr-4 {
  margin-right: 1rem;
}
.mt-10 {
  margin-top: 2.5rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mt-4 {
  margin-top: 1rem;
}
.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.block {
  display: block;
}
.inline {
  display: inline;
}
.flex {
  display: flex;
}
.grid {
  display: grid;
}
.hidden {
  display: none;
}
.h-1 {
  height: 0.25rem;
}
.h-2 {
  height: 0.5rem;
}
.h-20 {
  height: 5rem;
}
.h-4 {
  height: 1rem;
}
.h-40 {
  height: 10rem;
}
.h-60 {
  height: 15rem;
}
.h-\[20px\] {
  height: 20px;
}
.h-\[3px\] {
  height: 3px;
}
.h-full {
  height: 100%;
}
.max-h-full {
  max-height: 100%;
}
.w-2 {
  width: 0.5rem;
}
.w-20 {
  width: 5rem;
}
.w-3\/4 {
  width: 75%;
}
.w-32 {
  width: 8rem;
}
.w-60 {
  width: 15rem;
}
.w-80 {
  width: 20rem;
}
.w-\[20px\] {
  width: 20px;
}
.w-\[3px\] {
  width: 3px;
}
.w-fit {
  width: fit-content;
}
.w-full {
  width: 100%;
}
.w-max {
  width: max-content;
}
.min-w-\[20px\] {
  min-width: 20px;
}
.min-w-\[280px\] {
  min-width: 280px;
}
.min-w-\[360px\] {
  min-width: 360px;
}
.min-w-full {
  min-width: 100%;
}
.max-w-4xl {
  max-width: 56rem;
}
.max-w-\[280px\] {
  max-width: 280px;
}
.max-w-\[360px\] {
  max-width: 360px;
}
.max-w-full {
  max-width: 100%;
}
.max-w-xl {
  max-width: 36rem;
}
.flex-1 {
  flex: 1 1 0%;
}
.cursor-pointer {
  cursor: pointer;
}
.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.gap-1 {
  gap: 0.25rem;
}
.gap-10 {
  gap: 2.5rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-4 {
  gap: 1rem;
}
.gap-5 {
  gap: 1.25rem;
}
.gap-6 {
  gap: 1.5rem;
}
.gap-8 {
  gap: 2rem;
}
.gap-x-8 {
  column-gap: 2rem;
}
.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}
.self-start {
  align-self: flex-start;
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.text-ellipsis {
  text-overflow: ellipsis;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.\!rounded-md {
  border-radius: 0.375rem !important;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded-md {
  border-radius: 0.375rem;
}
.rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}
.border-b {
  border-bottom-width: 1px;
}
.border-\[\#6C6868\] {
  --tw-border-opacity: 1;
  border-color: rgb(108 104 104 / var(--tw-border-opacity, 1));
}
.border-default {
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity, 1));
}
.\!bg-\[\#ABABAC\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(171 171 172 / var(--tw-bg-opacity, 1)) !important;
}
.\!bg-\[\#FBE12B\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(251 225 43 / var(--tw-bg-opacity, 1)) !important;
}
.\!bg-default {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity, 1)) !important;
}
.\!bg-primary {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(119 128 130 / var(--tw-bg-opacity, 1)) !important;
}
.\!bg-success {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(51 119 66 / var(--tw-bg-opacity, 1)) !important;
}
.\!bg-transparent {
  background-color: transparent !important;
}
.bg-\[\#55efc4\] {
  --tw-bg-opacity: 1;
  background-color: rgb(85 239 196 / var(--tw-bg-opacity, 1));
}
.bg-\[\#6d28d9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(109 40 217 / var(--tw-bg-opacity, 1));
}
.bg-\[\#74b9ff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(116 185 255 / var(--tw-bg-opacity, 1));
}
.bg-\[\#a29bfe\] {
  --tw-bg-opacity: 1;
  background-color: rgb(162 155 254 / var(--tw-bg-opacity, 1));
}
.bg-\[\#ffeaa7\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 234 167 / var(--tw-bg-opacity, 1));
}
.bg-danger {
  --tw-bg-opacity: 1;
  background-color: rgb(228 38 14 / var(--tw-bg-opacity, 1));
}
.bg-default {
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity, 1));
}
.bg-link {
  --tw-bg-opacity: 1;
  background-color: rgb(50 146 250 / var(--tw-bg-opacity, 1));
}
.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(119 128 130 / var(--tw-bg-opacity, 1));
}
.bg-success {
  --tw-bg-opacity: 1;
  background-color: rgb(51 119 66 / var(--tw-bg-opacity, 1));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}
.bg-contain {
  background-size: contain;
}
.bg-cover {
  background-size: cover;
}
.bg-center {
  background-position: center;
}
.bg-no-repeat {
  background-repeat: no-repeat;
}
.object-cover {
  object-fit: cover;
}
.\!p-0 {
  padding: 0px !important;
}
.p-0 {
  padding: 0px;
}
.p-1 {
  padding: 0.25rem;
}
.p-4 {
  padding: 1rem;
}
.p-\[5px\] {
  padding: 5px;
}
.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.py-\[1px\] {
  padding-top: 1px;
  padding-bottom: 1px;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pb-5 {
  padding-bottom: 1.25rem;
}
.pb-8 {
  padding-bottom: 2rem;
}
.pl-2 {
  padding-left: 0.5rem;
}
.pl-4 {
  padding-left: 1rem;
}
.pr-0 {
  padding-right: 0px;
}
.pr-4 {
  padding-right: 1rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pt-3 {
  padding-top: 0.75rem;
}
.pt-4 {
  padding-top: 1rem;
}
.text-\[10px\] {
  font-size: 10px;
}
.text-\[14px\] {
  font-size: 14px;
}
.text-\[16px\] {
  font-size: 16px;
}
.text-\[6px\] {
  font-size: 6px;
}
.text-\[8px\] {
  font-size: 8px;
}
.text-\[9px\] {
  font-size: 9px;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.font-bold {
  font-weight: 700;
}
.font-normal {
  font-weight: 400;
}
.font-semibold {
  font-weight: 600;
}
.\!text-gray {
  --tw-text-opacity: 1 !important;
  color: rgb(85 85 85 / var(--tw-text-opacity, 1)) !important;
}
.text-\[\#868686\] {
  --tw-text-opacity: 1;
  color: rgb(134 134 134 / var(--tw-text-opacity, 1));
}
.text-danger {
  --tw-text-opacity: 1;
  color: rgb(228 38 14 / var(--tw-text-opacity, 1));
}
.text-gray {
  --tw-text-opacity: 1;
  color: rgb(85 85 85 / var(--tw-text-opacity, 1));
}
.text-success {
  --tw-text-opacity: 1;
  color: rgb(51 119 66 / var(--tw-text-opacity, 1));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.opacity-70 {
  opacity: 0.7;
}
.\!shadow-none {
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow) !important;
}
.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.hover\:cursor-pointer:hover {
  cursor: pointer;
}
.hover\:\!bg-\[\#979797\]:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(151 151 151 / var(--tw-bg-opacity, 1)) !important;
}
.hover\:\!bg-\[\#ffe100\]:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 225 0 / var(--tw-bg-opacity, 1)) !important;
}
@media (min-width: 640px) {
  .sm\:col-span-5 {
    grid-column: span 5 / span 5;
  }
  .sm\:col-span-7 {
    grid-column: span 7 / span 7;
  }
  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}
@media (min-width: 1024px) {
  .lg\:col-span-5 {
    grid-column: span 5 / span 5;
  }
  .lg\:col-span-7 {
    grid-column: span 7 / span 7;
  }
}
@media (min-width: 1280px) {
  .xl\:col-span-4 {
    grid-column: span 4 / span 4;
  }
  .xl\:col-span-8 {
    grid-column: span 8 / span 8;
  }
}
@media (min-width: 1536px) {
  .\32xl\:col-span-3 {
    grid-column: span 3 / span 3;
  }
  .\32xl\:col-span-9 {
    grid-column: span 9 / span 9;
  }
}

/*# sourceMappingURL=../../modules/project/dist/main.css.map */