/* temp_stylePlugin:src/main.css */
*,
::before,
::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}
::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.-bottom-1 {
  bottom: -0.25rem;
}
.bottom-2 {
  bottom: 0.5rem;
}
.left-4 {
  left: 1rem;
}
.left-96 {
  left: 24rem;
}
.right-2 {
  right: 0.5rem;
}
.right-4 {
  right: 1rem;
}
.right-7 {
  right: 1.75rem;
}
.right-96 {
  right: 24rem;
}
.top-0 {
  top: 0px;
}
.-m-1\.5 {
  margin: -0.375rem;
}
.m-auto {
  margin: auto;
}
.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-6 {
  margin-left: 1.5rem;
}
.ml-auto {
  margin-left: auto;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mr-4 {
  margin-right: 1rem;
}
.mr-5 {
  margin-right: 1.25rem;
}
.mr-\[26px\] {
  margin-right: 26px;
}
.mr-\[28px\] {
  margin-right: 28px;
}
.mt-10 {
  margin-top: 2.5rem;
}
.mt-16 {
  margin-top: 4rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-5 {
  margin-top: 1.25rem;
}
.mt-\[26px\] {
  margin-top: 26px;
}
.mt-\[2px\] {
  margin-top: 2px;
}
.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.line-clamp-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
.line-clamp-\[10\] {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.flex {
  display: flex;
}
.hidden {
  display: none;
}
.h-1 {
  height: 0.25rem;
}
.h-1\/3 {
  height: 33.333333%;
}
.h-6 {
  height: 1.5rem;
}
.h-\[138px\] {
  height: 138px;
}
.h-\[14px\] {
  height: 14px;
}
.h-\[15px\] {
  height: 15px;
}
.h-\[176px\] {
  height: 176px;
}
.h-\[20px\] {
  height: 20px;
}
.h-\[22px\] {
  height: 22px;
}
.h-\[33px\] {
  height: 33px;
}
.h-auto {
  height: auto;
}
.h-full {
  height: 100%;
}
.h-max {
  height: max-content;
}
.max-h-\[140px\] {
  max-height: 140px;
}
.min-h-\[397px\] {
  min-height: 397px;
}
.min-h-full {
  min-height: 100%;
}
.w-1\/3 {
  width: 33.333333%;
}
.w-10 {
  width: 2.5rem;
}
.w-16 {
  width: 4rem;
}
.w-2\/3 {
  width: 66.666667%;
}
.w-5 {
  width: 1.25rem;
}
.w-96 {
  width: 24rem;
}
.w-\[14px\] {
  width: 14px;
}
.w-\[167px\] {
  width: 167px;
}
.w-\[362px\] {
  width: 362px;
}
.w-\[65px\] {
  width: 65px;
}
.w-\[768px\] {
  width: 768px;
}
.w-\[99px\] {
  width: 99px;
}
.w-auto {
  width: auto;
}
.w-fit {
  width: fit-content;
}
.w-full {
  width: 100%;
}
.w-max {
  width: max-content;
}
.max-w-2xl {
  max-width: 42rem;
}
.max-w-\[125px\] {
  max-width: 125px;
}
.max-w-\[293px\] {
  max-width: 293px;
}
.max-w-\[60px\] {
  max-width: 60px;
}
.max-w-xs {
  max-width: 20rem;
}
.flex-1 {
  flex: 1 1 0%;
}
.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.resize {
  resize: both;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.items-start {
  align-items: flex-start;
}
.items-center {
  align-items: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.gap-1 {
  gap: 0.25rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-20 {
  gap: 5rem;
}
.gap-3 {
  gap: 0.75rem;
}
.gap-4 {
  gap: 1rem;
}
.gap-5 {
  gap: 1.25rem;
}
.gap-6 {
  gap: 1.5rem;
}
.gap-8 {
  gap: 2rem;
}
.gap-\[10px\] {
  gap: 10px;
}
.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.text-clip {
  text-overflow: clip;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.rounded-\[100px\] {
  border-radius: 100px;
}
.rounded-\[15px\] {
  border-radius: 15px;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded-md {
  border-radius: 0.375rem;
}
.rounded-xl {
  border-radius: 0.75rem;
}
.rounded-t-xl {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}
.border {
  border-width: 1px;
}
.border-b {
  border-bottom-width: 1px;
}
.border-\[\#CCCCCC\] {
  --tw-border-opacity: 1;
  border-color: rgb(204 204 204 / var(--tw-border-opacity, 1));
}
.border-default {
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity, 1));
}
.\!bg-danger {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(228 38 14 / var(--tw-bg-opacity, 1)) !important;
}
.\!bg-transparent {
  background-color: transparent !important;
}
.\!bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1)) !important;
}
.bg-\[\#CCCCCC\] {
  --tw-bg-opacity: 1;
  background-color: rgb(204 204 204 / var(--tw-bg-opacity, 1));
}
.bg-\[\#FBE12B80\] {
  background-color: #FBE12B80;
}
.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
}
.bg-body-background {
  --tw-bg-opacity: 1;
  background-color: rgb(243 243 243 / var(--tw-bg-opacity, 1));
}
.bg-danger {
  --tw-bg-opacity: 1;
  background-color: rgb(228 38 14 / var(--tw-bg-opacity, 1));
}
.bg-default {
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity, 1));
}
.bg-link {
  --tw-bg-opacity: 1;
  background-color: rgb(50 146 250 / var(--tw-bg-opacity, 1));
}
.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(119 128 130 / var(--tw-bg-opacity, 1));
}
.bg-success {
  --tw-bg-opacity: 1;
  background-color: rgb(51 119 66 / var(--tw-bg-opacity, 1));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}
.bg-center {
  background-position: center;
}
.bg-no-repeat {
  background-repeat: no-repeat;
}
.object-cover {
  object-fit: cover;
}
.p-0 {
  padding: 0px;
}
.p-1\.5 {
  padding: 0.375rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-4 {
  padding: 1rem;
}
.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}
.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.py-\[2px\] {
  padding-top: 2px;
  padding-bottom: 2px;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pt-\[1px\] {
  padding-top: 1px;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.text-5xl {
  font-size: 3rem;
  line-height: 1;
}
.text-\[10px\] {
  font-size: 10px;
}
.text-\[11px\] {
  font-size: 11px;
}
.text-\[14px\] {
  font-size: 14px;
}
.text-\[87px\] {
  font-size: 87px;
}
.text-\[9px\] {
  font-size: 9px;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.font-bold {
  font-weight: 700;
}
.font-normal {
  font-weight: 400;
}
.font-semibold {
  font-weight: 600;
}
.font-thin {
  font-weight: 100;
}
.not-italic {
  font-style: normal;
}
.leading-\[19px\] {
  line-height: 19px;
}
.leading-normal {
  line-height: 1.5;
}
.tracking-wider {
  letter-spacing: 0.05em;
}
.\!text-danger {
  --tw-text-opacity: 1 !important;
  color: rgb(228 38 14 / var(--tw-text-opacity, 1)) !important;
}
.text-\[\#3E3A3A\] {
  --tw-text-opacity: 1;
  color: rgb(62 58 58 / var(--tw-text-opacity, 1));
}
.text-\[\#6C6868\] {
  --tw-text-opacity: 1;
  color: rgb(108 104 104 / var(--tw-text-opacity, 1));
}
.text-\[\#6D6D6D\] {
  --tw-text-opacity: 1;
  color: rgb(109 109 109 / var(--tw-text-opacity, 1));
}
.text-\[\#A75836\] {
  --tw-text-opacity: 1;
  color: rgb(167 88 54 / var(--tw-text-opacity, 1));
}
.text-\[\#E4260E\] {
  --tw-text-opacity: 1;
  color: rgb(228 38 14 / var(--tw-text-opacity, 1));
}
.text-\[\#FBE12B\] {
  --tw-text-opacity: 1;
  color: rgb(251 225 43 / var(--tw-text-opacity, 1));
}
.text-danger {
  --tw-text-opacity: 1;
  color: rgb(228 38 14 / var(--tw-text-opacity, 1));
}
.text-gray-dark {
  --tw-text-opacity: 1;
  color: rgb(39 52 68 / var(--tw-text-opacity, 1));
}
.text-link {
  --tw-text-opacity: 1;
  color: rgb(50 146 250 / var(--tw-text-opacity, 1));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.shadow-\[0px_1px_4px_rgba\(0\,0\,0\,0\.5\)\] {
  --tw-shadow: 0px 1px 4px rgba(0,0,0,0.5);
  --tw-shadow-colored: 0px 1px 4px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.shadow-\[0px_3px_12px_0px_\#0000004D\] {
  --tw-shadow: 0px 3px 12px 0px #0000004D;
  --tw-shadow-colored: 0px 3px 12px 0px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.shadow-\[0px_4px_4px_0px_rgb\(0_0_0\/0\.25\)\] {
  --tw-shadow: 0px 4px 4px 0px rgb(0 0 0/0.25);
  --tw-shadow-colored: 0px 4px 4px 0px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.after\:absolute::after {
  content: var(--tw-content);
  position: absolute;
}
.after\:bottom-0::after {
  content: var(--tw-content);
  bottom: 0px;
}
.after\:left-0::after {
  content: var(--tw-content);
  left: 0px;
}
.after\:right-0::after {
  content: var(--tw-content);
  right: 0px;
}
.after\:mx-auto::after {
  content: var(--tw-content);
  margin-left: auto;
  margin-right: auto;
}
.after\:my-0::after {
  content: var(--tw-content);
  margin-top: 0px;
  margin-bottom: 0px;
}
.after\:h-1::after {
  content: var(--tw-content);
  height: 0.25rem;
}
.after\:w-1\/2::after {
  content: var(--tw-content);
  width: 50%;
}
.after\:content-normal::after {
  content: var(--tw-content);
  align-content: normal;
}
.after\:bg-black::after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
}
.hover\:cursor-pointer:hover {
  cursor: pointer;
}
.hover\:shadow-\[0px_4px_5px_0px_rgb\(0_0_0\/0\.40\)\]:hover {
  --tw-shadow: 0px 4px 5px 0px rgb(0 0 0/0.40);
  --tw-shadow-colored: 0px 4px 5px 0px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
@media (min-width: 640px) {
  .sm\:gap-3 {
    gap: 0.75rem;
  }
  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .sm\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
.\[\&\>i\]\:text-white > i {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

/*# sourceMappingURL=../../modules/recruiting/dist/main.css.map */