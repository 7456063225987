
    @keyframes polygon1-spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
      @keyframes polygon2-spin {
        from {
          transform: rotate(360deg);
        }
        to {
          transform: rotate(0deg);
        }
      }
    #svg-rotate1{
        animation: polygon1-spin infinite 10s linear;
    }
    #svg-rotate2{
        animation: polygon2-spin infinite 10s linear;
    }