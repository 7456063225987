
    .swiper.swiper-login{
        height: 100%;
        width: 100%;
        --swiper-pagination-color: white;
        --swiper-pagination-bullet-size: 0.75rem;
        --swiper-pagination-bullet-width: 0.75rem;
        --swiper-pagination-bullet-height: 0.75rem;
        --swiper-pagination-bullet-opacity: 1;
        --swiper-pagination-bullet-inactive-opacity: 1;
        --swiper-pagination-bullet-inactive-color: transparent;
        --swiper-pagination-bullet-horizontal-gap: 0.75rem;
        --swiper-theme-color: #122461;
    }

    .swiper .swiper-pagination .swiper-pagination-bullet {
        border: 2px solid white;
    }