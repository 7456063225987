
    .client-navbar.menu {
    padding: 0px
}
    .client-navbar.\!menu {
    padding: 0px
}
    .client-navbar .menu-item a {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem
}
